<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { PromoList, isStopPromo } from "@/api/marketing/coupon/case"; //接口api
import { updateInstoreStatus } from "@/api/purchase/index";
export default {
  name: "specialprice",
  components: { TablePage },
  dicts: ["bill_status", "marketing_promo_type"],
  data() {
    return {
      options: {
        uuid: "4f7a759e-22d6-20e2-2879-62438788987c",
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: PromoList,
        body: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "billId",
        search: [
          {
            label: "创建时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            clearable: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/活动方案编号/活动方案名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "活动方案编号" },
              { filter: "promoNames", label: "活动方案名称" },
            ],
          },
          this.$select({
            key: "executionStatus",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "isStopState",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "bill_status",
            option: {
              filter: "billStatuss",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "audit",
            label: "审核",
            type: "success",
            icon: "el-icon-document",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "enable",
            label: "启用",
            type: "warning",
            btnType: "dropdown",
            other: [
              {
                click: "redoEnable",
                label: "禁用",
              },
            ],
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "billNo",
            label: "活动方案编号",
            type: "link",
            click: "routerLink",
            minWidth: 200,
          },
          {
            prop: "promoName",
            label: "活动方案名称",
            minWidth: 155,
          },
          {
            prop: "billStatus",
            label: "活动方案状态",
            type: "dict",
            minWidth: 120,
            dict: "bill_status",
          },
          {
            prop: "promoType",
            label: "方案类型",
            type: "dict",
            minWidth: 120,
            dict: "marketing_promo_type",
          },
          {
            prop: "executionStatusName",
            label: "执行状态",
            minWidth: 100,
          },
          {
            prop: "begTime",
            label: "生效时间",
            minWidth: 160,
          },
          {
            prop: "endTime",
            label: "失效时间",
            minWidth: 160,
          },
          {
            prop: "isStop",
            label: "启用状态",
            minWidth: 160,
            formatter: (v) => (v ? "禁用" : "启用"),
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 160,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 160,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 160,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      switch (type) {
        case "routerLink":
          {
            const billId = row.billId;
            let promoType = row.promoType;
            let routerName = "";
            if (promoType === "0") routerName = "SpecialPromo";
            if (promoType === "1") routerName = "QuantitySpecial";
            if (promoType === "2") routerName = "FixedGroup";
            if (promoType === "3") routerName = "DynamicGroup";
            if (promoType === "4") routerName = "Discount";
            if (promoType === "5") routerName = "AmountDiscount";
            if (promoType === "6") routerName = "MoneyDiscount";
            if (promoType === "7") routerName = "MoneyFullDiscount";
            if (promoType === "8") routerName = "FulnessFullGift";
            if (promoType === "9") routerName = "RefillFree";
            if (promoType === "10") routerName = "GroupDiscounts";
            if (promoType === "11") routerName = "ResetDiscounts";
            this.$router.push({
              name: routerName,
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push("/marketing/promo/specialprice/components/SpecialScheme");
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            this.handleBillStatus(
              "审核",
              selectData,
              "/api/system/marketing/base/promo/case/updateStatus"
            );
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus(
              "反审核",
              selectData,
              "/api/system/marketing/base/promo/case/updateStatus"
            );
          }
          break;
        case "enable":
          {
            if (!selectData.length) return;
            this.handleBillStatus(
              "启用",
              selectData,
              "/api/system/marketing/base/promo/case/isStop"
            );
          }
          break;
        case "redoEnable":
          {
            if (!selectData.length) return;
            this.handleBillStatus(
              "禁用",
              selectData,
              "/api/system/marketing/base/promo/case/isStop"
            );
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus(
              "删除",
              selectData,
              "/api/system/marketing/base/promo/case/updateStatus"
            );
          }
          break;
        case "copy":
          {
            if (!selectData.length) return;
            this.handleBillStatus(
              "复制",
              selectData,
              "/api/system/marketing/base/promo/case/copy"
            );
          }
          break;
        default:
          break;
      }
    },
    seniorSearch() {},
    handleBillStatus(command, selectData, url) {
      let name = "";
      let billStatus = "";
      let isStop = "";
      if (command === "反审核") {
        name = "反审核";
        billStatus = "0";
      } else {
        if (command === "审核") {
          name = "审核";
          billStatus = "2";
        } else if (command === "删除") {
          name = "删除";
          billStatus = "3";
        } else if (command === "复制") {
          name = "复制";
          billStatus = "4";
        } else if (command === "启用") {
          name = "启用";
          isStop = false;
        } else if (command === "禁用") {
          name = "禁用";
          isStop = true;
        }
      }
      const billId = selectData.map((i) => i.billId);
      const billNo = selectData.map((i) => i.billNo);
      const $this = this;
      let str = billNo.length > 2 ? `${billNo[0]},${billNo[1]}...` : billNo;
      console.log("billNo", billNo);
      console.log("str", str);
      this.$modal
        // .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
        .confirm(`是否确认${name}单据编号为"` + str + '"的单据项?')
        .then(function () {
          if (["启用", "禁用"].includes(name)) {
            isStopPromo({ billIds: [...billId], isStop: isStop }).then((res) => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
              if (!isStop) {
                $this.$modal.msgSuccess("活动方案启用成功");
              } else {
                $this.$modal.msgSuccess("活动方案禁用成功");
              }
            });
          } else {
            return updateInstoreStatus([...billId], billStatus, url).then(() => {
              $this.$nextTick(() => {
                $this.options.check = [];
              });
              $this.$modal.msgSuccess(`${name}成功`);
              setTimeout(() => {
                $this.$refs.tablePage.getList();
              }, 500);
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
